import "core-js/stable";
import "regenerator-runtime/runtime";

import './common.scss'
import 'bootstrap'

import Uhepp from './components/Uhepp.jsx';

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Link } from "react-router-dom";

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid' 

export const fe = {
  React: React, 
  ReactDOM: ReactDOM,
  Uhepp: Uhepp, 
};
