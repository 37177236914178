import React, { useState, useEffect, useRef } from "react";
import { AxisLeft, AxisBottom } from '@vx/axis';
import { AxisRight, AxisTop } from '@vx/axis';
import { AreaStack } from '@vx/shape';
import { scaleLinear, scaleLog } from '@vx/scale';
import { Group } from '@vx/group';
import { extent } from 'd3-array';
import { mathjax } from 'mathjax-full/js/mathjax';
import { TeX } from 'mathjax-full/js/input/tex';
import { SVG } from 'mathjax-full/js/output/svg';
import { browserAdaptor } from 'mathjax-full/js/adaptors/browserAdaptor';
import { RegisterHTMLHandler } from 'mathjax-full/js/handlers/html';
import { STATE } from 'mathjax-full/js/core/MathItem';
import { preprocessData, sumBase, sumError, histogramify } from "../helpers/uhepp.js";

const getMaxBin = (uhepp) => {
  let max = 0;
	const edges = uhepp.bins.rebin || uhepp.bins.edges
  const n_bins = edges.length - 1
  for (let bin_i=0; bin_i < n_bins; bin_i++) {
    const density_scale = uhepp.bins.density_width ? uhepp.bins.density_width / (edges[bin_i + 1] - edges[bin_i]) : 1
    uhepp.stacks.forEach((stack, stack_index) => {
      let bottom = 0
      stack.content.forEach((stack_item, si_i) => {
        const y_value = sumBase(uhepp.yields, stack_item["yield"], bin_i + 1) * density_scale
        if (y_value > 0) { bottom += y_value; }
      })
      const whole_stack = stack.content.map(si => si["yield"]).flat()
      const statUp = sumError(uhepp.yields, whole_stack, bin_i + 1, stack.error) * density_scale
      if (statUp > 0) { bottom += statUp; }
      max = Math.max(max, bottom)
    })
  }
  return max;
}

const Badge = ({brand, label, subtext}) => (
  <>
   <text y={0} x={0}>
     { brand &&
       <tspan className="uhepp-brand">{brand}</tspan>
     }
     { label && 
       <tspan dx={3} className="uhepp-label">{label}</tspan>
     }
   </text>
   { subtext && subtext.split("\n").map((s, i) => 
      <MixedText key={i} x={0} y={(i+1) * 16}>{s}</MixedText>
    )
   }
 </>
)

const lineStyles = {
  ":": [1, 1],
  "--":  [5, 5],
  "-,": [3, 5, 1, 5],
}

const Step = ({
  xScale,
  yScale,
  x,
  y,
  style={},
  defaultColorsCycle,
  id,
  highlightedBin=false,
  onMouseOver=()=>null,
  onMouseOut=()=>null,
}) => {
  const points = Array(x.length).fill(0).map(
    (_, i) => [xScale(x[i]), yScale(y[i])]
  )

  // const str_token = points.map(([x, y], i) => `${i ? "L" : "M"}${x} ${y}`)
  // const data = [...str_token, "Z"].join(" ")
  //const str_token = points.map(([x, y], i) => `${x},${y}`)
  //const data = [...str_token].join(" ")

  const strokeWidth = style.linewidth || 1.5

  const dashArray = style.linestyle && lineStyles[style.linestyle] ? 
                       lineStyles[style.linestyle].map(x => x * strokeWidth).join(",")
                     : null

  return <g>
    { Array(x.length - 1).fill(0).map(
     (_, i) => <line x1={points[i][0]} y1={points[i][1]}
                     x2={points[i + 1][0]} y2={points[i + 1][1]}
                     key={i}
                     strokeDasharray={dashArray}
                     strokeWidth={strokeWidth}
                     stroke={style.color || defaultColorsCycle.get(id)}
                     opacity={i / 2 == highlightedBin ? 0.5 : 1}
                     />
    )}
  </g>
}


const computeEquidistent = (edges, density) => {
  if (density) {
    return density
  }

  let width = false
  for (let i = 0; i < edges.length - 1; i++) {
    const this_width = edges[i + 1] - edges[i]
    if (width === false || this_width == width) {
      width = this_width
    } else {
      return false
    }
  }
  return width
}

const Bar = ({
  xScale,
  yScale,
  leftEdge,
  rightEdge,
  bottom,
  y,
  id,
  style={},
  defaultColorsCycle,
  highlighted=false,
  onMouseOver=()=>null,
  onMouseOut=()=>null,
}) => (
  <rect x={xScale(leftEdge)}
        width={xScale(rightEdge) - xScale(leftEdge)}
        y={yScale(bottom + y)}
        height={yScale(bottom) - yScale(bottom + y)}
        fill={style.color || defaultColorsCycle.get(id)}
        opacity={highlighted ? 0.5 : 1.0}
        onMouseOver={() => onMouseOver()}
        onMouseOut={() => onMouseOut()} />
)

const Graph = ({
  xScale,
  yScale,
  data,
  style={},
}) => {
  const x = data.x
  const y = data.y
  const points = Array(x.length).fill(0).map(
    (_, i) => [xScale(x[i]), yScale(y[i])]
  )

  return <g>
    { Array(x.length - 1).fill(0).map(
     (_, i) => <line x1={points[i][0]} y1={points[i][1]}
                     x2={points[i + 1][0]} y2={points[i + 1][1]}
          strokeWidth="2"
          fillOpacity="0"
          stroke={style.color || "#000"}
          key={i}
          />
          )}
  </g>
}

const VLine = ({
  xScale,
  yScale,
  x,
  range,
  style={},
}) => (
    <line y1={yScale(range[0])}
          y2={yScale(range[1])}
          strokeWidth="2"
          stroke={style.color || "#000"}
          x1={xScale(x)}
          x2={xScale(x)} />
)

const Point = ({
  xScale,
  yScale,
  x,
  y,
  xErr=0,
  yErrUp=0,
  yErrDown=0,
  style={},
  highlighted=false,
  onMouseOver=()=>null,
  onMouseOut=()=>null,
}) => (
  <g onMouseOver={() => onMouseOver()}
     onMouseOut={() => onMouseOut()}>
    <circle cx={xScale(x)}
            cy={yScale(y)}
            fill={style.color || "#000"}
            opacity={highlighted ? 0.8 : 1.0}
            r="3" />
    <line y1={yScale(y)}
          y2={yScale(y)}
          stroke={style.color || "#000"}
          strokeWidth="2"
          strokeOpacity={highlighted ? 0.8 : 1.0}
          x1={xScale(x - xErr)}
          x2={xScale(x + xErr)} />
    <line y1={yScale(y + yErrUp)}
          y2={yScale(y - yErrDown)}
          strokeWidth="2"
          stroke={style.color || "#000"}
          strokeOpacity={highlighted ? 0.8 : 1.0}
          x1={xScale(x)}
          x2={xScale(x)} />
  </g>
)


class ColorCycle {
  constructor(colors) {
    this.colors = colors
    this.index = 0
    this.cache = {}
  }

  length() {
    return this.colors.length
  }

  get(id) {
    if (!this.cache.hasOwnProperty(id)) {
      const item = this.colors[this.index]
      this.index = (this.index + 1) % this.colors.length
      this.cache[id] = item
    }
    return this.cache[id]
  }
}

const Ratio = ({
  uhepp,
  ratioScale,
  xScale,
  defaultColorsCycle,
  highlightedBin,
  onMouseOverBin,
  children
}) => {
  const edges = uhepp.bins.rebin || uhepp.bins.edges
  const n_bins = edges.length - 1
  let objects = []

  uhepp.ratio.forEach((ratio_item, ri_i) => {

    if (ratio_item.type == "step" || ratio_item.type == "stepfilled") {
        const bin_indices = Array(n_bins).fill(0).map((_, i) => i)

        const numerator = bin_indices.map(i => sumBase(uhepp.yields, ratio_item.numerator, i + 1))
        const denominator = bin_indices.map(i => sumBase(uhepp.yields, ratio_item.denominator, i + 1))

        const stat_numUp = bin_indices.map(i => sumError(uhepp.yields, ratio_item.numerator, i + 1, ratio_item.error))
        const stat_denUp = bin_indices.map(i => sumError(uhepp.yields, ratio_item.denominator, i + 1, ratio_item.den_error || ratio_item.error))

        const stat_numDown = bin_indices.map(i => sumError(uhepp.yields, ratio_item.numerator, i + 1, ratio_item.error, false))
        const stat_denDown = bin_indices.map(i => sumError(uhepp.yields, ratio_item.denominator, i + 1, ratio_item.den_error || ratio_item.error, false))
        const y_values = (uhepp.ratio_axis && uhepp.ratio_axis.diff) ? 
                           bin_indices.map(i => numerator[i] - denominator[i])
                         : bin_indices.map(i => numerator[i] / denominator[i])
        const [new_x, new_y] = histogramify(edges, y_values)

        const statUp = (uhepp.ratio_axis && uhepp.ratio_axis.diff) ? 
                       stat_numUp
                     : bin_indices.map(i => stat_numUp[i] / denominator[i])

        const statDown = (uhepp.ratio_axis && uhepp.ratio_axis.diff) ? 
                       stat_numDown
                     : bin_indices.map(i => stat_numDown[i] / denominator[i])


        if (ratio_item.type == "stepfilled") {
          const bottom = (ratio_item.diff) ? 0 : 1;
          y_values.forEach((y_value, bin_i) => {
              objects.push(<Bar leftEdge={edges[bin_i]}
                                rightEdge={edges[bin_i + 1]}
                                bottom={bottom}
                                y={y_value - bottom}
                                xScale={xScale}
                                yScale={ratioScale}
                                style={ratio_item.style}
                                highlighted={highlightedBin == bin_i}
                                defaultColorsCycle={defaultColorsCycle}
                                id={`${ri_i}-${bin_i}`}
                                key={`key-${ri_i}-${bin_i}`}
                                onMouseOver={() => onMouseOverBin(bin_i)}
                                onMouseOut={() => onMouseOverBin(null)} />)
          })
        }
        const applicable_style = ratio_item.type == "step" ? ratio_item.style :
                                  Object.assign({}, ratio_item.style,
                                                {color: ratio_item.edgecolor || ratio_item.color})
        objects.push(<Step x={new_x}
                           y={new_y}
                           xScale={xScale}
                           yScale={ratioScale}
                           style={applicable_style}
                           defaultColorsCycle={defaultColorsCycle}
                           id={`${ri_i}`}
                           key={`${ri_i}`}
                           highlightedBin={highlightedBin}
                           onMouseOver={() => onMouseOverBin(bin_i)}
                           onMouseOut={() => onMouseOverBin(null)} />)

        statUp.forEach((s, bin_i) => {
          if (isFinite(s)) {
            objects.push(<rect
              key={`rect-num-uncert-${ri_i}-${bin_i}`}
              x={xScale(edges[bin_i])}
              width={xScale(edges[bin_i + 1]) - xScale(edges[bin_i])}
              y={ratioScale(y_values[bin_i] + s)}
              height={ratioScale(y_values[bin_i] - statDown[bin_i]) - ratioScale(y_values[bin_i] + s)}
              fill="url(#errorBand)"
              onMouseOver={() => onMouseOverBin(bin_i)}
              onMouseOut={() => onMouseOverBin(null)} />)
          }
        })

    } else if (ratio_item.type == "points") {
      for (let bin_i=0; bin_i < n_bins; bin_i++) {
        const numerator = sumBase(uhepp.yields, ratio_item.numerator, bin_i + 1)
        const denominator = sumBase(uhepp.yields, ratio_item.denominator, bin_i + 1)

        const stat_numUp = sumError(uhepp.yields, ratio_item.numerator, bin_i + 1, ratio_item.error)
        const stat_denUp = sumError(uhepp.yields, ratio_item.denominator, bin_i + 1, ratio_item.den_error || ratio_item.error)
        const stat_numDown = sumError(uhepp.yields, ratio_item.numerator, bin_i + 1, ratio_item.error, false)
        const stat_denDown = sumError(uhepp.yields, ratio_item.denominator, bin_i + 1, ratio_item.den_error || ratio_item.error, false)

        const y_value = (uhepp.ratio_axis && uhepp.ratio_axis.diff) ?
                          numerator - denominator
                        : numerator / denominator
        const statUp = ((uhepp.ratio_axis && uhepp.ratio_axis.diff) ?
                        stat_numUp
                      : stat_numUp / denominator)
        const statDown = ((uhepp.ratio_axis && uhepp.ratio_axis.diff) ?
                        stat_numDown
                      : stat_numDown / denominator)


        const bin_center = (edges[bin_i + 1] + edges[bin_i]) / 2
        const width = edges[bin_i + 1] - edges[bin_i]

        if (isFinite(statUp) && isFinite(statDown) && isFinite(y_value)) {
          if (ratio_item.keep_zero || numerator != 0 || statUp != 0 || statDown != 0)  {
            objects.push(<Point x={bin_center}
                                y={y_value}
                                xErr={width / 2}
                                yErrDown={statDown}
                                yErrUp={statUp}
                                xScale={xScale}
                                yScale={ratioScale}
                                key={`key-${ri_i}-${bin_i}`}
                                highlighted={highlightedBin == bin_i}
                                onMouseOver={() => onMouseOverBin(bin_i)}
                                style={ratio_item.style} />)
          }
        }
      }
    }

    for (let bin_i=0; bin_i < n_bins && ratio_item.erro != "no"; bin_i++) {
      const denominator = sumBase(uhepp.yields, ratio_item.denominator, bin_i + 1)
      const stat_denUp = sumError(uhepp.yields, ratio_item.denominator, bin_i + 1, ratio_item.den_error || ratio_item.error)
      const stat_denDown = sumError(uhepp.yields, ratio_item.denominator, bin_i + 1, ratio_item.den_error || ratio_item.error, false)
      const bin_center = (edges[bin_i + 1] + edges[bin_i]) / 2

      const rel_statUp = stat_denUp / denominator
      const rel_statDown = stat_denDown / denominator

      if (isFinite(rel_statUp) || isFinite(rel_statDown)) {
        objects.splice(0, 0, <rect
          key={`rect-uncert-${ri_i}-${bin_i}`}
          x={xScale(edges[bin_i])}
          width={xScale(edges[bin_i + 1]) - xScale(edges[bin_i])}
          y={ratioScale(1 + rel_statUp)}
          height={ratioScale(1 - rel_statDown) - ratioScale(1 + rel_statUp)}
          fill="url(#errorBand)"
          onMouseOver={() => onMouseOverBin(bin_i)}
          onMouseOut={() => onMouseOverBin(null)} />)
      }
    }
  })

  return objects
}

const Histogram = ({
  uhepp,
  yScale,
  xScale,
  defaultColorsCycle,
  highlightedBin,
  onMouseOverBin,
  children
}) => {

  const edges = uhepp.bins.rebin || uhepp.bins.edges
  const n_bins = edges.length - 1
  let objects = []


  // for (let bin_i=1; bin_i < n_bins; bin_i++) {
    uhepp.stacks.forEach((stack, stack_index) => {
      let bottom = Array(n_bins).fill(0)
      stack.content.forEach((stack_item, si_i) => {
        if (stack.type == "stepfilled") {
          for (let bin_i=0; bin_i < n_bins; bin_i++) {
            const density_scale = uhepp.bins.density_width ? uhepp.bins.density_width / (edges[bin_i + 1] - edges[bin_i]) : 1
            const y_value = sumBase(uhepp.yields, stack_item["yield"], bin_i + 1) * density_scale
            objects.push(<Bar leftEdge={edges[bin_i]}
                              rightEdge={edges[bin_i + 1]}
                              bottom={bottom[bin_i]}
                              y={y_value}
                              xScale={xScale}
                              yScale={yScale}
                              style={stack_item.style}
                              highlighted={highlightedBin == bin_i}
                              defaultColorsCycle={defaultColorsCycle}
                              id={`${stack_index}-${si_i}`}
                              key={`key-${stack_index}-${si_i}-${bin_i}`}
                              onMouseOver={() => onMouseOverBin(bin_i)}
                              onMouseOut={() => onMouseOverBin(null)} />)
            if (y_value > 0) {
              bottom[bin_i] += y_value
            }
          }
        } else if (stack.type == "step") {
          const bin_indices = Array(n_bins).fill(0).map((_, i) => i)
          const y_values = bin_indices.map(i => sumBase(uhepp.yields, stack_item["yield"], i + 1) * 
            (uhepp.bins.density_width ? uhepp.bins.density_width / (edges[i + 1] - edges[i]) : 1)
          )

          const [new_x, new_y] = histogramify(edges, y_values.map((v, i) => v + bottom[i]))

          objects.push(<Step x={new_x}
                             y={new_y}
                             xScale={xScale}
                             yScale={yScale}
                             style={stack_item.style}
                             defaultColorsCycle={defaultColorsCycle}
                             id={`${stack_index}-${si_i}`}
                             key={`${stack_index}-${si_i}`}
                             highlightedBin={highlightedBin}
                             onMouseOver={() => onMouseOverBin(bin_i)}
                             onMouseOut={() => onMouseOverBin(null)} />)

            y_values.forEach((y_value, bin_i) => {
              if (y_value > 0) {
                bottom[bin_i] += y_value
              }
            })
        } else if (stack.type == "points") {
          for (let bin_i=0; bin_i < n_bins; bin_i++) {
            const density_scale = uhepp.bins.density_width ? uhepp.bins.density_width / (edges[bin_i + 1] - edges[bin_i]) : 1

            const y_value = sumBase(uhepp.yields, stack_item["yield"], bin_i + 1) * density_scale
            const statUp = sumError(uhepp.yields, stack_item["yield"], bin_i + 1, stack.error) * density_scale
            const statDown = sumError(uhepp.yields, stack_item["yield"], bin_i + 1, stack.error, false) * density_scale
            const bin_center = (edges[bin_i + 1] + edges[bin_i]) / 2
            const width = edges[bin_i + 1] - edges[bin_i]

            if (isFinite(statUp) && isFinite(statDown) && isFinite(y_value)) {
              if (stack.keep_zero || y_value != 0 || statUp != 0 || statDown != 0)  {
                objects.push(<Point x={bin_center}
                                    y={bottom[bin_i] + y_value}
                                    xErr={width / 2}
                                    yErrUp={statUp}
                                    yErrDown={statDown}
                                    xScale={xScale}
                                    yScale={yScale}
                                    key={`key-${stack_index}-${si_i}-${bin_i}`}
                                    highlighted={highlightedBin == bin_i}
                                    onMouseOver={() => onMouseOverBin(bin_i)}
                                    style={stack_item.style} />)
                if (y_value > 0) {
                  bottom[bin_i] += y_value
                }
              }
                                  
            }
          }
        }
      })

			if (stack.type == "stepfilled" || stack.type == "step") {
        for (let bin_i=0; bin_i < n_bins; bin_i++) {
          const density_scale = uhepp.bins.density_width ? uhepp.bins.density_width / (edges[bin_i + 1] - edges[bin_i]) : 1
          const whole_stack = stack.content.map(si => si["yield"]).flat()

          const statUp = sumError(uhepp.yields, whole_stack, bin_i + 1, stack.error) * density_scale
          const statDown = sumError(uhepp.yields, whole_stack, bin_i + 1, stack.error, false) * density_scale
          objects.push(<rect
            key={`rect-${stack_index}-uncert-${bin_i}`}
            x={xScale(edges[bin_i])}
            width={xScale(edges[bin_i + 1]) - xScale(edges[bin_i])}
            y={yScale(bottom[bin_i] + statUp)}
            fill="url(#errorBand)"
            height={yScale(bottom[bin_i] - statDown) - yScale(bottom[bin_i] + statUp)}
            onMouseOver={() => onMouseOverBin(bin_i)}
            onMouseOut={() => onMouseOverBin(null)} />)
        }
      }
    })
  //}

  return objects
}

const adaptor = browserAdaptor();
RegisterHTMLHandler(adaptor);
function onError(math) {
  const {root, typesetRoot} = math;
  if (root.toString().substr(0,14) === 'math([merror([') {
    const merror = root.childNodes[0].childNodes[0];
    const text = merror.attributes.get('data-mjx-error') || merror.childNodes[0].childNodes[0].getText();
    adaptor.setAttribute(typesetRoot, 'data-mjx-error', text);
  }
}
function updateCSS(nodeID, text) {
  let styleNode = document.getElementById(nodeID);
  if (styleNode === null) {
    styleNode = document.createElement('style');
    styleNode.setAttribute('id', nodeID);
    document.head.appendChild(styleNode);
  }
  styleNode.innerHTML = text;
}

const tex = new TeX({packages: ['base', 'ams']});
const svg = new SVG({fontCache: 'none'});
const markErrors = [STATE.TYPESET + 1, null, onError];
const tex_html = mathjax.document('', {
  InputJax: tex,
  OutputJax: svg,
  renderActions: {
    markErrors,
  }
});

const EmbeddedMathJax = ({src, posX, posY, align="left"}) => {
  let html = tex_html;
  let node = document.createElement("div");
  const math = src.trim().replace('\\mathdefault{', '\\mathsf{');
  const metrics = svg.getMetricsFor(node, true);
  const outerHTML =  adaptor.outerHTML(html.convert(math, {
          display: true,
          ... metrics
  })); 
  html.updateDocument();
  updateCSS('MATHJAX-SVG-STYLESHEET', svg.cssStyles.cssText);
	node.innerHTML = outerHTML;
	let rendered_svg = node.getElementsByTagName("svg")[0];
	const scale = 6
	const width = rendered_svg.width.baseVal.valueInSpecifiedUnits * scale
	const transX = rendered_svg.width.baseVal.valueInSpecifiedUnits * scale / rendered_svg.viewBox.baseVal.width
	const transY = rendered_svg.height.baseVal.valueInSpecifiedUnits * scale / rendered_svg.viewBox.baseVal.height
  let alignTransX = 0
  if (align == "center") {
    alignTransX = -width / 2
  } else if (align == "right") {
    alignTransX = -width
  }

  return <g
            transform={`matrix(${transX} 0 0 ${transY} ${posX + alignTransX} ${posY})`}
					  dangerouslySetInnerHTML={{__html: rendered_svg.innerHTML}}></g>
}

const MixedText = ({children, x=0, y=0, align="left", opacity=1}) => {
  if (children.join) {
    children = children.join("")
  }
  const embedded = `\\textsf{${children}}`
  const switched = embedded.replace(/\$([^$]*)\$/g, "}$1\\textsf{")
  const cleared = switched.replace(/\\textsf\{\}/g, "")

  return <g opacity={1 * opacity}>
    <EmbeddedMathJax posX={x} posY={y} src={cleared} align={align} />
  </g>
}

const Legend = ({
  post_uhepp,
  setShowTotal,
  defaultColorsCycle,
  showTotal,
  highlightedBin
}) => {
  const lineSkip = -18;
  let i = 0
  let legend = [];
  (post_uhepp.graphs || []).forEach((graph, graph_i) => {
    legend.push(<>
      <line x1={0} x2={20}
        y1={(i + 1) * lineSkip - 5}
        y2={(i + 1) * lineSkip - 5}
        strokeWidth="2"
        stroke={graph.style.color || "#000"}
        key={`legend-${i}`}
        />
        <MixedText x={30} y={(i + 1) * lineSkip} 
                key={`legend-label-${i}`}>{graph.label}</MixedText>
    </>)

    i++
  })
  post_uhepp.stacks.map((stack, stack_index) => {
      stack.content.filter(item => item.label).map((stack_item, si_i) => {
        if (stack.type == "points") {
            legend.push(<Point x={10}
                                y={(i + 1) * lineSkip - 5}
                                xErr={10}
                                yErr={5}
                                xScale={(x) => x}
                                yScale={(y) => y}
                                key={`legend-${i}`}
                                onMouseOver={() => setShowTotal(true)}
                                onMouseOut={() => setShowTotal(false)}
                                style={stack_item.style} />)
        } else if (stack.type == "step") {
          legend.push(<Step x={[0, 20]}
                            y={[5, 5]}
                            y={Array(2).fill((i + 1) * lineSkip - 5)}
                            xScale={(x) => x}
                            yScale={(y) => y}
                            style={Object.assign({}, stack_item.style, {linewidth: 2})}
                            onMouseOver={() => setShowTotal(true)}
                            onMouseOut={() => setShowTotal(false)}
                            highlightedBin={null}
                            defaultColorsCycle={defaultColorsCycle}
                            id={`${stack_index}-${si_i}`}
                            key={`legend-${stack_index}-${si_i}`} />)
        } else {
          legend.push(<Bar leftEdge={0}
                            rightEdge={20}
                            bottom={(i + 1) * lineSkip}
                            y={-10}
                            xScale={(x) => x}
                            yScale={(y) => y}
                            style={stack_item.style}
                            onMouseOver={() => setShowTotal(true)}
                            onMouseOut={() => setShowTotal(false)}
                            defaultColorsCycle={defaultColorsCycle}
                            id={`${stack_index}-${si_i}`}
                            key={`legend-${stack_index}-${si_i}`} />)
        }
        let showLabel = false
        if (highlightedBin != null) {
          const y_value = sumBase(post_uhepp.yields, stack_item["yield"], highlightedBin + 1)
          const statUp = sumError(post_uhepp.yields, stack_item["yield"], highlightedBin + 1, stack.error)
          const statDown = sumError(post_uhepp.yields, stack_item["yield"], highlightedBin + 1, stack.error, false)

          const label = "$" + y_value.toFixed(1) + "^{+" + statUp.toFixed(1) + "}_{-" + statDown.toFixed(1) + "}$"
          legend.push(<MixedText fontSize={10} x={30} y={(i + 1) * lineSkip}
                key={`legend-text-${i}`}>{label}</MixedText>)
        } else if (showTotal) {
          const y_value = sumBase(post_uhepp.yields, stack_item["yield"])
          const statUp = sumError(post_uhepp.yields, stack_item["yield"], null, stack.error)
          const statDown = sumError(post_uhepp.yields, stack_item["yield"], null, stack.error, false)

          const label = "$" + y_value.toFixed(1) + "^{+" + statUp.toFixed(1) + "}_{-" + statDown.toFixed(1) + "}$"
          legend.push(<MixedText fontSize={10} x={30} y={(i + 1) * lineSkip}
                key={`legend-text-${i}`}>{label}</MixedText>)

        } else {
          showLabel = true
        }
        const label = stack_item.label
        legend.push(<MixedText x={30} y={(i + 1) * lineSkip} opacity={showLabel}
                key={`legend-label-${i}`}>{label}</MixedText>)
        i++;
      })
  })

  return <Group top={-(i + 1) * lineSkip}>{legend}</Group>
}

const UheppHist = ({width, height, uhepp}) => {
  const post_uhepp = Object.assign({}, uhepp, {
      yields: preprocessData({
        yields: uhepp.yields,
        old_edges: uhepp.bins.edges,
        new_edges: uhepp.bins.rebin, 
        include_underflow: uhepp.bins.include_underflow, 
        include_overflow: uhepp.bins.include_overflow, 
      })
  })


  return <UheppHistPost width={width} height={height} uhepp={post_uhepp} />
}

const UheppHistPost = ({width, height, uhepp}) => {
  const post_uhepp = uhepp  // backwards compatibility

  const [highlightedBin, setHighlightedBin] = useState(null)
  const [showTotal, setShowTotal] = useState(false)
  const [pngDownload, setPngDownload] = useState(false)
  const [pngInProgress, setPngInProgress] = useState(false)

  const canvasHeight = height * 2
  const canvasWidth = width * 2
  const canvasRef = useRef(null)
  const svgRef = useRef(null)

  useEffect(() => {
		setPngDownload(false)
		setPngInProgress(false)
	}, [uhepp])

  const generatePng = () => {
    setPngInProgress(true)
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const svg = svgRef.current.cloneNode(true)
    svg.setAttribute("height", `${canvasHeight}px`)
    svg.setAttribute("width", `${canvasWidth}px`)
    const data = (new XMLSerializer()).serializeToString(svg)

    const svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})
    const url = URL.createObjectURL(svgBlob)

    const img = new Image()
		img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
			ctx.drawImage(img, 0, 0)
			URL.revokeObjectURL(url)

			canvas.toBlob(blob => {
				const pngUrl = URL.createObjectURL(blob)
				setPngDownload(pngUrl)
				setPngInProgress(false)
			}, "image/png")
		}
		img.src = url
  }

  const defaultColorsCycle = new ColorCycle([
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
    "#1f77b4",
  ])


	const margin = {
		top: 5,
		bottom: 40,
		left: 80,
		right: 10,
    sep: 3,
	}

	const xMax = width - margin.left - margin.right
	let yMax = height - margin.top - margin.bottom 
  let ratioMax = 0 
  const hasRatio = (uhepp.ratio && uhepp.ratio.length)
  if (hasRatio) {
    const ratioFraction = (uhepp.layout && uhepp.layout.ratio_fraction) || 0.25
	  yMax = (height - margin.top - margin.bottom - margin.sep) * (1 - ratioFraction)
	  ratioMax = (height - margin.top - margin.bottom - margin.sep) * (ratioFraction)
  }

	const xScale = scaleLinear({
		range: [0, xMax],
		domain: extent(uhepp.bins.rebin || uhepp.bins.edges),
	})
	const maxBinMain = getMaxBin(post_uhepp)
  const isScaleLin = !(uhepp.y_axis && uhepp.y_axis.log)
  const yScaleMin = (uhepp.y_axis && uhepp.y_axis.min) ?? (isScaleLin ? 0 : 1);
  const yScaleMax = (uhepp.y_axis && uhepp.y_axis.max) ?? (isScaleLin ?  maxBinMain * 1.5 : maxBinMain * 10**1.5 );


	const yScale = isScaleLin ? (
    scaleLinear({
      range: [yMax, 0],
      domain: [yScaleMin, yScaleMax],
    })
  ) : (
    scaleLog({
      range: [yMax, 0],
      domain: [yScaleMin, yScaleMax],
      clamp: true,
    })
  )

  const isRatioScaleLin = !(uhepp.ratio_axis && uhepp.ratio_axis.log)
  const ratioScaleMaker = isRatioScaleLin ? scaleLinear : scaleLog
	const ratioScale = ratioScaleMaker({
		range: [ratioMax, 0],
		domain: [
      (uhepp.ratio_axis && uhepp.ratio_axis.min) ?? 0.5,
      (uhepp.ratio_axis && uhepp.ratio_axis.max) ?? 1.5,
    ],
    clamp: !isRatioScaleLin,
	})

  const equidistent = computeEquidistent(uhepp.bins.rebin || uhepp.bins.edges, uhepp.bins.density_width)

	return (<>
  <div className="uhepp-container">
			<svg viewBox={`0 0 ${width} ${height}`}
           ref={svgRef}
           onMouseOut={() => setHighlightedBin(null)} >
        <style>
        svg * {'{font-family: sans-serif}'}
        rect {'{shape-rendering: crispEdges }'}
        .uhepp-brand {'{font-style: italic; font-weight: bold}'}
        .uhepp-brand, .uhepp-label {'{font-size: 20px}'}
        </style>
        <defs>
          <clipPath id="main-clip">
            <rect x={0} y={0} width={xMax} height={yMax} />
          </clipPath>
          { hasRatio && 
            <clipPath id="ratio-clip">
              <rect x={0} y={0} width={xMax} height={ratioMax} />
            </clipPath>
          }
          <pattern id="errorBand" patternUnits="userSpaceOnUse" width="6" height="6">
            <path d="M-1,1 l1,-1 M0,6 l6,-6 M5,7 l2,-2" style={{stroke: "#666",  strokeWidth: 1}} />
          </pattern>
        </defs>
				<Group top={margin.top} left={margin.left}>
          <g clipPath="url(#main-clip)">
            <Histogram
               uhepp={post_uhepp}
               highlightedBin={highlightedBin}
               onMouseOverBin={binIndex => setHighlightedBin(binIndex)}
               defaultColorsCycle={defaultColorsCycle}
               xScale={xScale}
               yScale={yScale}
             />
           { (post_uhepp.v_lines || []).map((vline, i) => 
            <VLine
               xScale={xScale}
               yScale={yScale}
               x={vline.x}
               range={vline.range || [0, maxBinMain]}
               key={i}
               style={vline.style} />
             )
           }
           { (post_uhepp.graphs || []).map((graph, i) => 
            <Graph
               xScale={xScale}
               yScale={yScale}
               data={graph}
               key={i}
               style={graph.style} />
             )
           }

         </g>

        <Group top={26} left={13}>
          <Badge brand={uhepp.badge.brand} label={uhepp.badge.label} subtext={uhepp.badge.subtext} />
        </Group>

        <Group top={5} left={xMax * 0.65}>
          <Legend post_uhepp={post_uhepp}
                  setShowTotal={setShowTotal}
                  showTotal={showTotal}
                  defaultColorsCycle={defaultColorsCycle}
                  highlightedBin={highlightedBin} />
        </Group>

		 		<AxisBottom
						scale={xScale}
						top={yMax}
						stroke={'#1b1a1e'}
						tickTextFill={'#1b1a1e'}
						tickTransform="translate(0, -8)"
					  tickComponent={hasRatio ? (props) => <></> : undefined}
					/>
		 		<AxisBottom
						scale={xScale}
						top={yMax}
            numTicks={50}
						stroke={'#1b1a1e'}
            tickLength={4}
						tickTransform="translate(0, -4)"
					  tickComponent={(props) => <></>}
					/>
        { !hasRatio && 
          <MixedText y={yMax + 30} x={xMax - 10} align="right">
            {uhepp.variable.name ? uhepp.variable.name + ' ' : ''}
            {uhepp.variable.symbol}
            {uhepp.variable.unit ?
              (uhepp.variable.unit_in_brackets ?  ' [' + uhepp.variable.unit + ']' : ' / ' + uhepp.variable.unit)
             : '' }
          </MixedText>
        }

				<AxisLeft
						scale={yScale}
						top={0}
						left={0}
						stroke={'#1b1a1e'}
						tickTextFill={'#1b1a1e'}
						tickTransform="translate(8, 0)"
					/>
        <g transform={`matrix(0 -1 1 0 ${-margin.left + 20} 10)`}>
          <MixedText y={0} x={0} align="right">
            { (uhepp.y_axis && !uhepp.y_axis.append_unit && uhepp.y_axis.label) ? uhepp.y_axis.label
              : ((uhepp.y_axis && uhepp.y_axis.append_unit && uhepp.y_axis.label) ? uhepp.y_axis.label : "Events") +  " / " +
              (equidistent ? equidistent + (uhepp.variable.unit ? " " + uhepp.variable.unit : '') : 'Bin')
            }
          </MixedText>
        </g>

				<AxisLeft
						scale={yScale}
						top={0}
						left={0}
						stroke={'#1b1a1e'}
            numTicks={50}
						tickTransform="translate(4, 0)"
            tickLength={4}
					  tickComponent={(props) => <></>}
					/>
				<AxisRight
						scale={yScale}
						top={0}
						left={xMax}
						stroke={'#1b1a1e'}
					  tickComponent={(props) => <></>}
						tickTransform="translate(-8, 0)"
					/>
				<AxisRight
						scale={yScale}
						top={0}
						left={xMax}
						stroke={'#1b1a1e'}
            numTicks={50}
					  tickComponent={(props) => <></>}
            tickLength={4}
						tickTransform="translate(-4, 0)"
					/>
					<AxisTop
						scale={xScale}
						top={0}
						stroke={'#1b1a1e'}
					  tickComponent={(props) => <></>}
						tickTransform="translate(0, 8)"
					/>
					<AxisTop
						scale={xScale}
						top={0}
						stroke={'#1b1a1e'}
            numTicks={50}
					  tickComponent={(props) => <></>}
            tickLength={4}
						tickTransform="translate(0, 4)"
					/>
				</Group>

    { (post_uhepp.ratio && post_uhepp.ratio.length > 0) &&
		<Group top={margin.top + margin.sep + yMax} left={margin.left}>
         <g clipPath="url(#ratio-clip)">
           <Ratio
              uhepp={post_uhepp}
              highlightedBin={highlightedBin}
              onMouseOverBin={binIndex => setHighlightedBin(binIndex)}
              defaultColorsCycle={defaultColorsCycle}
              xScale={xScale}
              ratioScale={ratioScale}
            />
        </g>
		 		<AxisBottom
						scale={xScale}
						top={ratioMax}
						stroke={'#1b1a1e'}
						tickTextFill={'#1b1a1e'}
						tickTransform="translate(0, -8)"
					/>
		 		<AxisBottom
						scale={xScale}
						top={ratioMax}
            numTicks={50}
						stroke={'#1b1a1e'}
            tickLength={4}
						tickTransform="translate(0, -4)"
					  tickComponent={(props) => <></>}
					/>
        <MixedText y={ratioMax + 30} x={xMax - 10} align="right">
          {uhepp.variable.name ? uhepp.variable.name + ' ' : ''}
          {uhepp.variable.symbol}
          {uhepp.variable.unit ?
            (uhepp.variable.unit_in_brackets ?  ' [' + uhepp.variable.unit + ']' : ' / ' + uhepp.variable.unit)
           : '' }
        </MixedText>

				<AxisLeft
						scale={ratioScale}
						top={0}
						left={0}
						stroke={'#1b1a1e'}
						tickTextFill={'#1b1a1e'}
            numTicks={5}
						tickTransform="translate(8, 0)"
					/>
        { uhepp.ratio_axis && uhepp.ratio_axis.label && 
          <g transform={`matrix(0 -1 1 0 ${-margin.left + 20} ${ratioMax / 2})`}>
            <MixedText y={0} x={0} align="center">
              { uhepp.ratio_axis.label }
            </MixedText>
          </g>
        }

				<AxisLeft
						scale={ratioScale}
						top={0}
						left={0}
						stroke={'#1b1a1e'}
            numTicks={20}
						tickTransform="translate(4, 0)"
            tickLength={4}
					  tickComponent={(props) => <></>}
					/>
				<AxisRight
						scale={ratioScale}
						top={0}
						left={xMax}
            numTicks={5}
						stroke={'#1b1a1e'}
					  tickComponent={(props) => <></>}
						tickTransform="translate(-8, 0)"
					/>
				<AxisRight
						scale={ratioScale}
						top={0}
						left={xMax}
						stroke={'#1b1a1e'}
            numTicks={20}
					  tickComponent={(props) => <></>}
            tickLength={4}
						tickTransform="translate(-4, 0)"
					/>
					<AxisTop
						scale={xScale}
						top={0}
						stroke={'#1b1a1e'}
					  tickComponent={(props) => <></>}
						tickTransform="translate(0, 8)"
					/>
					<AxisTop
						scale={xScale}
						top={0}
						stroke={'#1b1a1e'}
            numTicks={50}
					  tickComponent={(props) => <></>}
            tickLength={4}
						tickTransform="translate(0, 4)"
					/>
        </Group> }
			</svg>
  </div>
  
  <div className="container text-right">
      <canvas className="d-none" ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
      { !pngDownload &&
        <button className="btn btn-sm btn-outline-primary"
                disabled={pngInProgress}
                onClick={() => generatePng()}>
          { !pngInProgress && <span>
            <i className="fas fa-download"></i> Convert to .png
          </span> }
          { pngInProgress && <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">
              </span> Converting...
          </> }
        </button> }
      { pngDownload &&
        <a href={pngDownload} download={uhepp.metadata.filename + ".png"} className="btn btn-sm btn-primary">
          <i className="fas fa-download"></i> Download .png
        </a> }
  </div>
	</>)

}
export default UheppHist;
