import React, { useState, useEffect } from "react";
import UheppHistUI from "./UheppHistUI.jsx";

const Error = (message) => (
  <div className="alert m-3 alert-danger">
    <h4 className="alert-heading">Rendering failed</h4>
    <p>The interactive plot rendering failed with the following error message:</p>
    <p style={{fontFamily: "monospace"}}>{message}</p>
    <hr />
    <p>You can download the JSON file and try to fix the issue. Please note that
    the web rendering is yet not fully supported.</p>
  </div>)


class UheppWithData extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
		return {error: error.toString()}
	}

  render() {
    if (this.state.error) {
			return Error(this.state.error)
		}
		const uhepp = this.props.uhepp;

		if (!uhepp) {
			return Error("Invalid uhepp data")
		}
		if (!uhepp.version) {
			return Error("Missing uhepp version")
		}
		if (uhepp.version != "0.1" && uhepp.version != "0.2" && uhepp.version != "0.3" && uhepp.version != "0.4") {
			return Error(`Unsupported uhepp version: ${uhepp.version}`)
		}

		if (uhepp.type == "histogram") {
			return  <UheppHistUI
								width={this.props.width}
								height={this.props.height}
                uuid={this.props.uuid}
                size={this.props.size}
								uhepp={uhepp} />
		}

	  return Error(`Unknown or missing plot type: ${uhepp.type}`)
	}

}


const Uhepp = ({width, height, uuid}) => {
  const [uhepp, setUhepp] = useState(null);

  const url = "/api/plots/" + uuid
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(url);
      const size = response.headers.get("Content-Length")
      const json = await response.json();
      setUhepp({uhepp: json.uhepp, size: size})
    }
    fetchData();
  }, [url]);

  if (uhepp) {
    return <UheppWithData width={width} height={height} size={uhepp.size} uhepp={uhepp.uhepp} uuid={uuid} />
  } else {
    return  (<>
      <div className="d-flex justify-content-center my-5">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <div className="text-center">Loading data...</div>
    </>)
  }

}

export default Uhepp;
