
/**
 * Helper function to aggregate yields and uncertainties.
 *
 * If a process included in the list cannot be found in the yields objects, this
 * process is resolved to zero yield. This method uses the "base" attribute of
 * the yield objects.
 * 
 * @param yields          The object holding all yield objects from a plot. The
 *                        keys are assumed to appear in process_names.
 * @param process_names   List of names of the processes to include in the sum.
 *                        The order of the names is arbitrary.
 * @param property        Name of the property to use from the yield object,
 *                        i.e., "base" or "stat"; or a function that takes the
 *                        process object and returns the value.
 * @param pre             Callback that maps bin-process values before summing
 *                        them. Should be identify for the yields, and the
 *                        square for statistical uncetainties.
 * @param post            Callback that maps bin-process values after summing
 *                        them to undo the effect of pre. Should be identify for
 *                        the yields, and the square root for statistical
 *                        uncetainties.
 * @param bin_index       If set, computes the sum of yields on the specified
 *                        bin. The index refers to the array index of "base".
 * @return                post(sum(pre(bin-process-values)))
 */
export const aggregate = ({
  yields,
  process_names,
  property,
  pre,
  post,
  bin_index
}) => {
  const getBase = (process_name) => {
    if (process_name.indexOf("/") != -1) {
      if (property != "base") {
        return 0
      }
      const [name, variation, updown] = process_name.split("/")
      if (!yields.hasOwnProperty(name)) {
        return 0
      }
      const process = yields[name]
      const var_obj = "var_" + updown
      if (!process.hasOwnProperty(var_obj) || !process[var_obj].hasOwnProperty(variation)) {
        // Fall back
        return aggregate({yields, process_names: [name], property, pre, post, bin_index})
      }

      const base = process[var_obj][variation]
      return (bin_index != null) ? base[bin_index] : base;

    } else {
      if (!yields.hasOwnProperty(process_name)) {
        return 0
      }
      const process = yields[process_name]
      if (!(property instanceof Function) && !process.hasOwnProperty(property)) {
        return 0
      }

      // Property could be a function
      const base = (property instanceof Function) ? property(process) : process[property]
      return (bin_index !== null) ? base[bin_index] : base;
    }
  }
  const agg = process_names.map(getBase).flat().map(pre).reduce((a, b) => a + b, 0)
  return post(agg)
}

/**
 * Compute the total yield from a process list.
 *
 * If a process included in the list cannot be found in the yields objects, this
 * process is resolved to zero yield. This method uses the "base" attribute of
 * the yield objects.
 * 
 * @param yields          The object holding all yield objects from a plot. The
 *                        keys are assumed to appear in process_names.
 * @param process_names   List of names of the processes to include in the sum.
 *                        The order of the names is arbitrary.
 * @param bin_index       If set, computes the sum of yields on the specified
 *                        bin. The index refers to the array index of "base".
 * @return                The sum of yields
 */
export const sumBase = (yields, process_names, bin_index=null) => (
  aggregate({
    yields,
    process_names,
    property: "base",
    pre: (x) => x,
    post: (x) => x,
    bin_index
  })
)

/**
 * Compute the total statistical uncertainty from a process list.
 *
 * If a process included in the list cannot be found in the yields objects, this
 * process is resolved to 0 uncertainty. This method uses the "stat" attribute of
 * the yield objects.
 * 
 * @param yields          The object holding all yield objects from a plot. The
 *                        keys are assumed to appear in process_names.
 * @param process_names   List of names of the processes to include in the sum.
 *                        The order of the names is arbitrary.
 * @param bin_index       If set, computes the sum of yields on the specified
 *                        bin. The index refers to the array index of "base".
 * @return                The sum of yields
 */
export const sumStat = (yields, process_names, bin_index=null) => (
  aggregate({
    yields,
    process_names,
    property: "stat",
    pre: (x) => x * x,
    post: (x) => Math.sqrt(x),
    bin_index
  })
)
/**
 * Compute the total uncertainty from a process list and an error_string.
 *
 * If a process included in the list cannot be found in the yields objects, this
 * process is resolved to 0 uncertainty. This method sums the stats, syst and
 * envelop uncertainties in quadrature according to the error_string.
 * 
 * @param yields          The object holding all yield objects from a plot. The
 *                        keys are assumed to appear in process_names.
 * @param process_names   List of names of the processes to include in the sum.
 *                        The order of the names is arbitrary.
 * @param bin_index       If set, computes the sum of yields on the specified
 *                        bin. The index refers to the array index of "base".
 * @param error_string    A string combining 'stat', 'syst', and 'env' joined
 *                        with '+'; or 'no'. The independend contributations are
 *                        added in quadrature assuming that the uncertainty is
 *                        uncorrelated.
 * @return                The sum of yields
 */
export const sumError = (yields, process_names, bin_index=null,
                         error_string='stat', up=true) => {
  if (error_string == "no") {
    return aggregate({
      yields,
      process_names,
      property: "base",
      pre: (x) => 0,
      post: (x) => 0,
      bin_index
    })
  }


  const components = error_string.split("+")
  const varprop = {false: "var_down", true: "var_up"}[up]
  const all_variations = components.indexOf("env") == -1 ? [] : (
    process_names.map(name => yields[name].hasOwnProperty(varprop) ?  Object.keys(yields[name][varprop]) : []).flat()
  )

  let totals2 = [];  
  // Stat
  if (components.indexOf("stat") != -1) {
    totals2.push(aggregate({
      yields,
      process_names,
      property: "stat",
      pre: (x) => x,
      post: (x) => x * x,
      bin_index
    }))
  } 
  // Syst
  if (components.indexOf("syst") != -1) {
    totals2.push(aggregate({
      yields,
      process_names,
      property: "syst",
      pre: (x) => x,
      post: (x) => x * x,
      bin_index
    }))
  } 

  // Env
  const base = aggregate({
      yields,
      process_names,
      property: "base",
      pre: (x) => x,
      post: (x) => x,
      bin_index
  })

  const updown = {true: "up", false: "down"}[up]
  all_variations.forEach(varname => {
    const varValues = aggregate({
      yields,
      process_names: process_names.map(n => `${n}/${varname}/${updown}`),
      property: "base",
      pre: (x) => x,
      post: (x) => x,
      bin_index
    })
    totals2.push((varValues - base) * (varValues - base))
  })

  return Math.sqrt(totals2.reduce((a, b) => a + b, 0))
}

/**
 * Rebin a single series of values.
 *
 * @param series          Array of bin contents
 * @param old_edges       Bin boundaries of the original series array. Should
 *                        have one more value than series.
 * @param new_edges       Bin boundaries of the target series.
 * @param pre             Callback that maps values before summing them. Should
 *                        be identify for the yields, and the square for
 *                        statistical uncetainties.
 * @param post            Callback that maps  values after summing them to undo
 *                        the effect of pre. Should be identify for the yields,
 *                        and the square root for statistical uncertainties.
 * @return                Copy of the series using the new binning
 */
export const rebin = (
  series,
  old_edges,
  new_edges,
  pre = (x) => x,
  post = (x) => x,
) => {
  if (series.length != old_edges.length + 1) {
    throw new Error("Incompatible number of values and edges")
  }
  new_edges.forEach(e => {
    if (old_edges.indexOf(e) < 0) {
      throw new Error("New edges are not a subset of old edges")
    }
  })
  const ref_edges = [old_edges[0] - 1, ...old_edges]
  const target_index = series.map((value, i) => {
    const low_edge = ref_edges[i];
    const bins_to_the_left = new_edges.map((x, i) => [x, i]).filter(d => d[0] <= low_edge).map(d => d[1]).reverse()
    if (bins_to_the_left.length > 0) {
      return bins_to_the_left[0] + 1
    }
    return 0
  })

  const range = Array(new_edges.length + 1).fill(0).map((x, i) => i)
  const result = range.map(i => 
    series.filter((v, j) => target_index[j] == i).map(x => pre(x)).reduce((a, b) => a + b, 0)
  )
  return result.map(x => post(x))
}

export const fromEntires = (entries) => {
  let result = {}
  entries.forEach(([key, value]) => {
    result[key] = value
  })
  return result
}

export const objmap = (obj, map) => fromEntires(Object.entries(obj).map(([key, value]) => [key, map(value, key)]))
export const objfilter = (obj, filter) => fromEntires(Object.entries(obj).filter(([key, value]) => filter(value, key)))

/**
 * Processes the yield objects and returns a new version
 *
 * The preprocessing encompasses rebinning, and the merging of overflow and
 * underflow bins.
 *
 * @param yields             Uhepp yields objects to be processed
 * @param old_edges          List of original bin boundaries
 * @param new_edges          Optional list of new bin boundaries, must be subset
 *                           of original boundaries
 * @param include_underflow  If true, merge content of underflow to first bin
 * @param include_overflow   If true, merge content of overflow to last bin
 * @return                   Copy of rebinned yields
 */
export const preprocessData = ({
  yields,
  old_edges,
  new_edges=null,
  include_underflow,
  include_overflow,
}) => fromEntires(Object.entries(yields).map(([name, yield_obj]) => {
  const eff_edges = new_edges ? new_edges : old_edges
  const rebinned = {
    "base": rebin(yield_obj.base, old_edges, eff_edges),
    "stat": yield_obj.stat ? rebin(yield_obj.stat, old_edges, eff_edges, (x) => x*x, (x)=>Math.sqrt(x)) : null,
    "syst": yield_obj.syst ? rebin(yield_obj.syst, old_edges, eff_edges, (x) => x*x, (x)=>Math.sqrt(x)) : null,
    "var_up": yield_obj.var_up ? objmap(yield_obj.var_up,
      (value) => rebin(value, old_edges, eff_edges)
    ) : null,
    "var_down": yield_obj.var_down ? objmap(yield_obj.var_down,
      (value) => rebin(value, old_edges, eff_edges)
    ) : null,
  }
  if (include_underflow) {
    rebinned.base[1] += rebinned.base[0]
    rebinned.base[0] = 0
    if (rebinned.stat) {
      rebinned.stat[1] = Math.sqrt(rebinned.stat[0]**2 + rebinned.stat[1]**2)
      rebinned.stat[0] = 0
    }
  }
  if (include_overflow) {
    const b = rebinned.base.length - 1
    rebinned.base[b - 1] += rebinned.base[b]
    rebinned.base[b] = 0
    if (rebinned.stat) {
      rebinned.stat[b - 1] = Math.sqrt(rebinned.stat[b]**2 + rebinned.stat[b - 1]**2)
      rebinned.stat[b] = 0
    }
  }
  return [name, fromEntires(Object.entries(rebinned).filter(
    ([name, values]) => !!values
  ))]
}))


/**
 * Converts the paired series of edges and y values to the points of a step outline.
 *
 * The y values are doubled piecewise. The edges must be n+1 values. The edges
 * are doubled piecewise and the first and last item are droped.
 *
 * @param edges     Bin edges of the histogram
 * @param y         Bin contents for each bin
 * @return          Pair of edges and y values with doubled entries
 */
export const histogramify = (edges, y) => {
  if (edges.length != y.length + 1) {
    throw new Error("Incompatible number of values and edges")
  }

  const new_y = y.map(v => [v, v]).flat()
  const doubled_edges = edges.map(v => [v, v]).flat()
  const new_x = doubled_edges.slice(1, doubled_edges.length - 1)

  return [new_x, new_y]
}
