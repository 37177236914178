import React, { useState, useEffect } from "react";
import UheppHist from "./UheppHist.jsx";
import {
  HashRouter as Router,
  Route,
  Switch,
  useLocation,
  useHistory,
  useParams
} from "react-router-dom";
import { objfilter, objmap } from "../helpers/uhepp.js";
import Cookies from 'js-cookie'
import { SketchPicker } from 'react-color';

const noVariationUsed = (uhepp) => {
  const main_names = uhepp.stacks.map(stack => stack.content.map(item => item.yields)).flat()
  const num_names = uhepp.ratio ? uhepp.ratio.map(item => item.numerator).flat() : []
  const den_names = uhepp.ratio ? uhepp.ratio.map(item => item.denominator).flat() : []
  const names = [main_names, den_names, num_names].flat()
    
  const varied_names = names.filter(name => (name && name.indexOf("/") != -1))
  return varied_names.length == 0
}

const variationList = (uhepp) => {
  return Array.from(new Set(Object.entries(uhepp.yields).map(([key, item]) => [
      ...Object.keys(item.var_up || {}),
      ...Object.keys(item.var_down || {})
  ]).flat())).sort()
}

const varstyle = (updown) => {
  if (updown == "up") {
    return {color: "#ff0000"}
  } if (updown == "down") {
    return {color: "#0000ff"}
  }  else {
    return {}
  }
}

const makeVariationStacks = (uhepp, stackId, variation, updown) => {
  const stack = uhepp.stacks[stackId]
  if (!stack) {
    return []
  }
  return  [{
            type: "step", 
            content: [{
              style: varstyle(updown),
              "label": updown,
              "yield": stack.content.map(item => 
                item.yield.map(name => `${name}/${variation}/${updown}`)
              ).flat()
            }]
          }]
}

const makeVariationRatio = (uhepp, stackId, variation, updown) => {
  const stack = uhepp.stacks[stackId]
  if (!stack) {
    return []
  }
  return  [{
            type: "step", 
            style: varstyle(updown),
            numerator: stack.content.map(item => 
                item.yield.map(name => `${name}/${variation}/${updown}`)
              ).flat(),
            denominator: stack.content.map(item => 
                item.yield.map(name => `${name}`)
              ).flat()
          }]
}

const SingleBadge = ({tag}) => (<>
  <span className="badge badge-pill badge-primary badge-outline-primary mx-1">{tag}</span>
  { ' ' }
</>)

const BadgePair = ({tag, value}) => (<>
  <span className="badge-pair mx-1">
    <span className="badge badge-pill badge-primary badge-outline-primary">{tag}</span>
    <span className="badge badge-pill badge-outline-primary text-dark">{value}</span>
  </span>
  { ' ' }
</>)

const BadgeList = ({tags}) => (
  Object.entries(tags).map(([key,  value], i) => 
				value == null ?
        <SingleBadge tag={key} key={i} /> : <BadgePair key={i} tag={key} value={value} />
  )
)

const DelayedSelect = ({timeout=300, ...props}) => {
  return <select  {...props} />
}

const DelayedInput = ({value, onChange, onType=null, timeout=300, ...props}) => {
  const [text, setText] = useState(value)
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    setText(value);
  }, [value])

  const handleChange = (e) => {
    const content = e.target.value
    setText(content)
    if (timer) {
      clearTimeout(timer)
    }
    if (onType) {
      onType(e)
    }
    setTimer(setTimeout(() => {
      onChange({"target": {"value": content}})
    }, timeout))
  }

  return <input value={text || ""} onChange={(e) => handleChange(e)} {...props} />

}


const FileSize = ({value}) => {
  value = parseInt(value)
  const unit = ["Byes", "KiB", "MiB", "GiB"]
  const divider = 1024;
  const threshold = 2;

  let i = 0;
  for (; i < unit.length; i++) {
    if (value >= threshold * divider) {
      value /= divider
    } else {
      break
    }
  }
  const formatted_value = (i==0) ? value.toFixed(0) : value.toFixed(1)

  return <span>{formatted_value} {unit[i]}</span>
}

const ColorPicker = ({value, onChange}) => {
  const [modal, setModal] = useState(false)
  const [current, setCurrent] = useState(value)
  
  useEffect(() => {
    setCurrent(value);
  }, [value])

  const handleChangeComplete = (c) => {
    setCurrent(c)
    onChange({'target': {value: c}})
  }

  return (<>
        <div className="input-group-prepend">
          <span className="input-group-text">Color</span>
        </div>
        <DelayedInput type="text" className="form-control" placeholder="color"
          value={current}
          onType={(e) => setCurrent(e.target.value)}
          onChange={(e) => handleChangeComplete(e.target.value)} />

        <div className="input-group-append" onClick={() => setModal(!modal)}>
          <span className="input-group-text" style={{
            backgroundColor: current || "#fff",
            cursor: "pointer",
            width: "3rem"
          }}> </span>
        </div>
        { modal &&
          <div className="p-3">
            <SketchPicker color={current}
                presetColors={
                  ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f',
                   '#bfbd22', '#17beff', '#dddddd', '#222222', '#d0e1f2', '#94c4df', '#4a98c9', '#1764ab']
                }
                onChange={c => setCurrent(c.hex)}
                onChangeComplete={c => handleChangeComplete(c.hex)} />
         </div>
        }
  </>)

}
const UheppHistUIWithSyst = ({
  width,
  height,
  uhepp,
  uuid,
  size,
  onEnvChange,
  onEnvStackChange,
  onReset,
  onStackContentChange,
  onStackItemRename,
  onStackItemChangeColor,
  onAddStackItem,
  onDeleteStackItem,
  onMoveUpStackItem,
  onMoveDownStackItem,
  onMoveUpStack,
  onMoveDownStack,
  onDeleteStack,
  onAddStack,
  onStackTypeChange,
  onMoveUpRatioItem,
  onMoveDownRatioItem,
  onDeleteRatioItem,
  onAddRatioItem,
  onRatioItemTypeChange,
  onRatioItemNumeratorChange,
  onRatioItemDenominatorChange,
  onRatioItemChangeColor,
  isVariationReady,
  variations,
  origStacks,
  origRatio,
  origYields,
  envName,
  envId,
  setIsClean,
  isClean
}) => {
  const [include_underflow, setUnderflow] = useState(!!uhepp.bins.include_underflow)
  const [include_overflow, setOverflow] = useState(!!uhepp.bins.include_overflow)
  const [rebin, setRebin] = useState(uhepp.bins.rebin || uhepp.bins.edges)
  const [density_width, setDensity] = useState(uhepp.bins.density_width)
  const [collections, setCollections] = useState(null);
  const [destination, setDestination] = useState("")
  const [saveStatus, setSaveStatus] = useState(null)

  const url = "/api/collections"
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(url);
      const json = await response.json();
      setCollections(json)
      if (json.length > 0) {
        setDestination(json[0].url)
      }
    }
    fetchData();
  }, [url]);

  const handleDestination = (e) => {
    setDestination(e.target.value)
    setSaveStatus(null)
  }

  const reset = () => {
    setUnderflow(!!uhepp.bins.include_underflow)
    setOverflow(!!uhepp.bins.include_overflow)
    setRebin(uhepp.bins.rebin || uhepp.bins.edges)
    setDensity(uhepp.bins.density_width)
    onReset()
  }

  const handleRebin = (e) => {
    let values = Array.from(e.target.selectedOptions, option => parseFloat(option.value))
    setRebin(values)
    setIsClean(false)
  }


  const handleUnderflow = (e) => {
    let value = e.target.checked
    setUnderflow(value)
    setIsClean(false)
  }
  const handleOverflow = (e) => {
    let value = e.target.checked
    setOverflow(value)
    setIsClean(false)
  }
  const handleDensityWidth = (e) => {
    let value = parseFloat(e.target.value)
    setDensity(value)
    setIsClean(false)
  }

  // Fallback to original rebin or original binning
  const takeFirstIfSubset = (rebin, orig) => {
    if (!rebin) {
      return orig
    }
    let isSubset = true
    rebin.forEach(e => {
      if (orig.indexOf(e) < 0) {
        isSubset = false
      }
    })
    return isSubset ? rebin : orig
  }
  const rebin_singleFallback = rebin.length > 1 ? rebin : (uhepp.bins.rebin)
  const rebin_subsetFallback = takeFirstIfSubset(rebin_singleFallback, uhepp.bins.edges)

  const uhepp_derived = Object.assign({}, uhepp,
      {bins: Object.assign({}, uhepp.bins, {
          rebin: rebin_subsetFallback,
          include_underflow,
          include_overflow,
          density_width,
      })})

	const save = () => {
		const csrftoken = Cookies.get('csrftoken');

    async function asyncStringify(str) {
        return JSON.stringify(str);
    }

		const data = {
			"collection": destination,
      "uhepp": Object.assign({}, uhepp_derived, {
        yields: origYields,
        metadata: Object.assign({}, uhepp_derived.metadata, {
          tags: Object.assign({}, uhepp_derived.metadata.tags, {
            "forked": uuid
          })
        })
      })
    }

    async function fetchData() {
      const response = await fetch("/api/plots/", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
						'X-CSRFToken': csrftoken
        },
        body: await asyncStringify(data)
		  })
      if (response.ok) {
        setSaveStatus(null)
        const json = await response.json()
        setIsClean(true)
        window.location = "/p/" + json.uuid
      } else {
        setSaveStatus('error')
      }
    }
    setSaveStatus('loading')
    fetchData()
	}

  return <>
		<div className="badge-container"><BadgeList tags={uhepp.metadata.tags} /></div>

    <UheppHist width={width} height={height} uhepp={uhepp_derived} />

		<ul className="nav nav-tabs" id="view-options" role="tablist">
			<li className="nav-item">
				<a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">
          <i className="d-none d-lg-inline fas fa-info-circle mr-1"></i>
          Info
        </a>
			</li>
			<li className="nav-item">
				<a className="nav-link" id="binning-tab" data-toggle="tab" href="#binning" role="tab" aria-controls="binning" aria-selected="true">
          <i className="d-none d-lg-inline fas fa-chart-bar mr-1"></i>
          Binning
        </a>
			</li>
			<li className="nav-item">
				<a className="nav-link" id="stacks-tab" data-toggle="tab" href="#stacks" role="tab" aria-controls="stacks" aria-selected="false">
          <i className="d-none d-lg-inline fas fa-layer-group mr-1"></i>
          Stacks
        </a>
			</li>
			<li className="nav-item">
				<a className="nav-link" id="ratio-tab" data-toggle="tab" href="#ratio" role="tab" aria-controls="ratio" aria-selected="false">
          <i className="d-none d-lg-inline fas fa-percentage mr-1"></i>
          Ratio
        </a>
			</li>
			<li className="nav-item">
				<a className="nav-link" id="variations-tab" data-toggle="tab" href="#variations" role="tab" aria-controls="variations" aria-selected="false">
          <i className="d-none d-lg-inline fas fa-envelope mr-1"></i>
          Variations
        </a>
			</li>
			<li className="nav-item">
				<a className="nav-link" id="reset-tab" data-toggle="tab" href="#reset" role="tab" aria-controls="reset" aria-selected="false">
          <i className="d-none d-lg-inline fas fa-trash-restore mr-1"></i>
          Restore
        </a>
			</li>
			<li className="nav-item">
				<a className="nav-link" id="save-tab" data-toggle="tab" href="#save" role="tab" aria-controls="save" aria-selected="false">
          <i className="d-none d-lg-inline fas fa-code-branch mr-1"></i>
          Fork
        </a>
			</li>
		</ul>

		<div className="tab-content" id="view-options-content">
			<div className="tab-pane show active p-3" id="info" role="tabpanel" aria-labelledby="info-tab">
				<dl>
					<dt>Author</dt>
					<dd>{ uhepp.metadata.author || <i>None</i>}</dd>
					<dt>Date</dt>
					<dd>{ uhepp.metadata.date ? (new Date(uhepp.metadata.date)).toString() : <i>None</i>}</dd>
					<dt>Producer</dt>
					<dd>{ uhepp.metadata.producer || <i>None</i>}</dd>
					<dt>Code revision</dt>
					<dd>{ uhepp.metadata.code_revision || <i>None</i>}</dd>
					<dt>Event selection</dt>
					<dd>{ uhepp.metadata.event_selection || <i>None</i>}</dd>
					<dt>Total integrated luminosity</dt>
					<dd>{ uhepp.metadata.lumi_ifb ?
								<span>{ uhepp.metadata.lumi_ifb} fb<sup>-1</sup></span> : <i>None</i>}</dd>
					<dt>Center-of-mass energy</dt>
					<dd>{ uhepp.metadata.Ecm_TeV ?
								uhepp.metadata.Ecm_TeV + " TeV" : <i>None</i>}</dd>
					<dt>Tags</dt>
          <dd className="badge-container">
            <BadgeList tags={uhepp.metadata.tags} />
          </dd>
          { size && <>
					<dt>File size</dt>
          <dd>
            <FileSize value={size} />
          </dd>
          </>}
				</dl>
			</div>
			<div className="tab-pane p-3" id="binning" role="tabpanel" aria-labelledby="binning-tab">
				<form>
					<div className="form-group">
						<label htmlFor="rebin">Bin edges</label>
						<select multiple size={10} className="form-control" id="rebin" onChange={(e) => handleRebin(e)}
                value={rebin}>
							{uhepp.bins.edges.map((v, i) => 
								<option value={v} key={i}>{v}</option>
							)}
						</select>
					</div>

					<div className="form-check">
						<input className="form-check-input" type="checkbox"
							checked={include_underflow == true} id="checkUnderflow"
							onChange={e => handleUnderflow(e)} />
						<label className="form-check-label" htmlFor="checkUnderflow">
							Include underflow events in first bin
						</label>
					</div>

					<div className="form-check">
						<input className="form-check-input" type="checkbox"
							checked={include_overflow == true} id="checkOverflow" 
							onChange={e => handleOverflow(e)} />
						<label className="form-check-label" htmlFor="checkOverflow">
							Include overflow events in last bin
						</label>
					</div>

					<div className="form-group">
						<label htmlFor="densityWidth">Normalize yields to</label>
						<div className="input-group">
							<input type="number" className="form-control" id="densityWidth"
								onChange={e => handleDensityWidth(e)}
								value={density_width || "0"} />
              { uhepp.variable.unit &&
                <div className="input-group-append">
                  <span className="input-group-text">{uhepp.variable.unit}</span>
                </div>
              }
						</div>
					</div>
				</form>
			</div>
			<div className="tab-pane p-3" id="stacks" role="tabpanel" aria-labelledby="stacks-tab">
        <form>
          { origStacks.map((stack, i) => (<div key={i}>
            <h3 className="controls-head mt-2">
              <span>Stack {i}</span>
              <span>
                  <button disabled={i == 0} className="btn btn-outline-primary mx-1" onClick={(e) => onMoveUpStack(e, i)}>
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button disabled={(i + 1) >= origStacks.length} className="btn btn-outline-primary mx-1" onClick={(e) => onMoveDownStack(e, i)}>
                    <i className="fas fa-arrow-down"></i>
                  </button>
                <button className="btn btn-outline-danger ml-1" onClick={(e) => onDeleteStack(e, i)}>
                  <i className="fas fa-trash"></i>
                </button>
              </span>
            </h3>
            <div className="from-group">
              <label htmlFor={`t-${i}`}>Histogram type</label> 
              <select id={`t-${i}`} value={stack.type} className="form-control" onChange={(e) => onStackTypeChange(e, i)}>
                <option value="step">step</option>
                <option value="stepfilled">stepfilled</option>
                <option value="points">points</option>
              </select>
            </div>
            { stack.content.map((content, j) =>  (
            <div key={`${j}_${stack.content.length}`}>
            <h5>Item {j}</h5>
            <div className="form-row mb-2">
              <div className="col-md-4">


                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Label</span>
                  </div>
                  <DelayedInput type="text" className="form-control" placeholder="label"
                value={content.label} onChange={(e) => onStackItemRename(e, i, j)}/>
                </div>

                <div className="input-group my-1">
                  <ColorPicker value={(content.style) ? content.style.color || "" : ""} onChange={(e) => onStackItemChangeColor(e, i, j)} />
                </div>

                <div className="my-1">
                  <button className="btn btn-sm btn-outline-danger mr-1" onClick={(e) => onDeleteStackItem(e, i, j)}>
                    <i className="fas fa-trash"></i>
                  </button>
                  <button disabled={j == 0} className="btn btn-sm btn-outline-primary mx-1" onClick={(e) => onMoveUpStackItem(e, i, j)}>
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button disabled={(j + 1) >= stack.content.length} className="btn btn-sm btn-outline-primary mx-1" onClick={(e) => onMoveDownStackItem(e, i, j)}>
                    <i className="fas fa-arrow-down"></i>
                  </button>
                </div>

              </div>
              <div className="col-md-8">
                  <DelayedSelect multiple size={8} value={content.yield} id={`p-${i}-${j}`} className="form-control"
                  onChange={(e) => onStackContentChange(e, i, j)}>
                    { Object.keys(uhepp.yields).map((name, i) => <option value={name} key={i}>{name}</option>) }
                  </DelayedSelect>
              </div>
            </div>

            </div>
            ))}
            <div className="my-1">
              <button className="btn btn-sm btn-outline-primary" onClick={(e) => onAddStackItem(e, i)}>
                <i className="fas fa-plus mr-1"></i>
                Add item {stack.content.length} to stack
              </button>
            </div>


          </div>))}
          
          <div className="my-3">
            <button className="btn btn-outline-primary" onClick={(e) => onAddStack(e)}>
              <i className="fas fa-plus mr-1"></i>
              Add stack {origStacks.length}
            </button>
          </div>
        </form>
			</div>

			<div className="tab-pane p-3" id="ratio" role="tabpanel" aria-labelledby="ratio-tab">
        <form>
          { origRatio.map((ratioitem, i) => (<div key={i}>
            <h3 className="controls-head mt-2">
              <span>Ratio item {i}</span>
              <span>
                  <button disabled={i == 0} className="btn btn-outline-primary mx-1" onClick={(e) => onMoveUpRatioItem(e, i)}>
                    <i className="fas fa-arrow-up"></i>
                  </button>
                  <button disabled={(i + 1) >= origRatio.length} className="btn btn-outline-primary mx-1" onClick={(e) => onMoveDownRatioItem(e, i)}>
                    <i className="fas fa-arrow-down"></i>
                  </button>
                <button className="btn btn-outline-danger ml-1" onClick={(e) => onDeleteRatioItem(e, i)}>
                  <i className="fas fa-trash"></i>
                </button>
              </span>
            </h3>

            <div className="form-row mb-2">
              <div className="col-md-4">
                <div className="form-group mb-1">
                  <label htmlFor={`t-${i}`}>Histogram type</label>
                  <select id={`t-${i}`} value={ratioitem.type} className="form-control" onChange={(e) => onRatioItemTypeChange(e, i)}>
                    <option value="step">step</option>
                    <option value="points">points</option>
                  </select>
                </div>
                <div className="input-group my-1">
                  <ColorPicker value={(ratioitem.style) ? ratioitem.style.color || "" : ""} onChange={(e) => onRatioItemChangeColor(e, i)} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={`p-${i}`}>Numerator</label>
                  <DelayedSelect multiple size={8} value={ratioitem.numerator} id={`p-${i}`} className="form-control"
                  onChange={(e) => onRatioItemNumeratorChange(e, i)}>
                    { Object.keys(uhepp.yields).map((name, i) => <option value={name} key={i}>{name}</option>) }
                  </DelayedSelect>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor={`p-${i}}`}>Denominator</label>
                  <DelayedSelect multiple size={8} value={ratioitem.denominator} id={`p-${i}`} className="form-control"
                  onChange={(e) => onRatioItemDenominatorChange(e, i)}>
                    { Object.keys(uhepp.yields).map((name, i) => <option value={name} key={i}>{name}</option>) }
                  </DelayedSelect>
                </div>
              </div>

            </div>
          </div>))}
          
          <div className="my-3">
            <button className="btn btn-outline-primary" onClick={(e) => onAddRatioItem(e)}>
              <i className="fas fa-plus mr-1"></i>
              Add ratio item {origRatio.length}
            </button>
          </div>
        </form>
			</div>

			<div className="tab-pane p-3" id="variations" role="tabpanel" aria-labelledby="variations-tab">
        { !isVariationReady && 
          <p>To use the variation feature, you must add variation data to
          the <code>yields</code> object. Please note that the stacks must not use
          any varied yield in the default view.</p> }
        { isVariationReady && 
        <form>
					<div className="form-group">
						<label htmlFor="envelop">Add envelope of </label>
						<select value={envName} className="form-control" id="envelop" onChange={(e) => onEnvChange(e)}>
              { [
                <option value="NOMINAL" key={"nonminal"}>Nominal</option>,
                ...variations.map((name, i) => <option value={name} key={i}>{name}</option>)
                ]
              }
            </select>
          </div>
					<div className="form-group">
						<label htmlFor="env-stack">for</label>
						<select value={envId} className="form-control" id="env-stack" onChange={(e) => onEnvStackChange(e)}>
              { origStacks.map((stack, i) => <option value={i} key={i}>Stack {i} ({stack.content.length} items, {stack.type})</option>) }
            </select>
          </div>
        </form>
        }
			</div>

			<div className="tab-pane p-3" id="reset" role="tabpanel" aria-labelledby="reset-tab">
        <p>Reset all view modifications. Pull the plot to make permanent
        changes.</p>
				<button className="btn btn-secondary m-1" type="button" onClick={() => reset()}>
					Reset view
				</button>
			</div>

			<div className="tab-pane p-3" id="save" role="tabpanel" aria-labelledby="save-tab">
				<form>
					<p>Save the current view into another collection.</p>
					<div className="form-group">
						<label htmlFor="env-stack">Destination collection</label>
						{ collections == null ?
							<div className="d-flex justify-content-center">
								<div className="spinner-border text-primary" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							</div> :
							<select value={destination} className="form-control" onChange={(e) => handleDestination(e)}>
								{ collections.map((c, i) => <option value={c.url} key={i}>{c.title}</option>) }
							</select>
						}
					</div>	

          <p>
					<button className="btn btn-secondary" type="button" onClick={() => save()} disabled={saveStatus == 'loading'}>
						Save as new plot
            { saveStatus == 'loading' &&  <>
              <span className="ml-2 spinner-border spinner-border-sm" role="status"> </span>
              <span className="sr-only">Loading...</span>
            </>}
					</button>
          </p>
          {saveStatus == 'loading' && 
					<small className="text-muted">Depending on the size of the data, this
          might take some time{ size && <span> (file size <FileSize value={size} />)</span>}.</small>
          }
          {saveStatus == 'error' && 
          <p className="alert alert-danger">
            Something went wrong. Please try again and make sure you have permission to write to that collection.
          </p> }
				</form>
			</div>

		</div>
  </>
}

const patchList = (iter, i, repl) => iter.map((v, j) => (i == j) ? repl : v)

const UheppHistUIRouted = ({width, height, uhepp, uuid, size}) => {
  const {envName, envId} = Object.assign({envName: "NOMINAL", envId: 0}, useParams())
  const history = useHistory()

  const [stacks, setStacks] = useState(uhepp.stacks)
  const [ratio, setRatio] = useState(uhepp.ratio || [])
  const [isClean, setIsClean] = useState(true)

  const getClean = () => isClean

  window.onbeforeunload = () => {
    if (!isClean) {
      return "Really exit?"
    }
  }

  const reset = () => {
    history.push("/")
    setStacks(uhepp.stacks)
    setRatio(uhepp.ratio || [])
    setIsClean(true)
  }
  const handleEnvelop = (e) => {
    let variationName = e.target.value
    history.push(`/${variationName}/${envId}`)
    setIsClean(false)
  }
  const handleEnvStack = (e) => {
    let stackId = parseInt(e.target.value)
    history.push(`/${envName}/${stackId}`)
    setIsClean(false)
  }

  const handleStackContentChange = (e, stackId, itemId) => {
    const yields = Array.from(e.target.selectedOptions, option => option.value)
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": patchList(stacks[stackId].content, itemId, Object.assign({},
      stacks[stackId].content[itemId], {"yield": yields}))})))
    setIsClean(false)
  }

  const handleStackItemRename = (e, stackId, itemId) => {
    const label = e.target.value
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": patchList(stacks[stackId].content, itemId, Object.assign({},
      stacks[stackId].content[itemId], {"label": label}))})))
    setIsClean(false)
  }

  const handleStackItemChangeColor = (e, stackId, itemId) => {
    const color = e.target.value
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": patchList(stacks[stackId].content, itemId, Object.assign({},
      stacks[stackId].content[itemId], {"style": 
      Object.assign({}, stacks[stackId].content[itemId].style || {}, {"color":
      color})}))})))
    setIsClean(false)
  }

  const handleAddStackItem = (e, stackId) => {
    e.preventDefault()
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": [...stacks[stackId].content, {yield: [], label: "New item",
      style: {}}]})))
    setIsClean(false)
  }

  const handleMoveUpStackItem = (e, stackId, item) => {
    e.preventDefault()
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": [
      ...stacks[stackId].content.filter((_, i) => i < (item-1)),
      stacks[stackId].content[item],
      stacks[stackId].content[item - 1],
      ...stacks[stackId].content.filter((_, i) => i > item),
      ]})))
    setIsClean(false)
  }

  const handleMoveDownStackItem = (e, stackId, item) => {
    e.preventDefault()
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": [
      ...stacks[stackId].content.filter((_, i) => i < item),
      stacks[stackId].content[item + 1],
      stacks[stackId].content[item ],
      ...stacks[stackId].content.filter((_, i) => i > item + 1),
      ]})))
    setIsClean(false)
  }

  const handleDeleteStackItem = (e, stackId, itemId) => {
    e.preventDefault()
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId],
      {"content": stacks[stackId].content.filter((_, j) => j != itemId)})))
    setIsClean(false)
  }
  
  const handleDeleteStack = (e, stackId) => {
    e.preventDefault()
    setStacks(stacks.filter((_, i) => i != stackId))
    setIsClean(false)
  }
  
  const handleAddStack = (e) => {
    e.preventDefault()
    setStacks([...stacks, {"type": "stepfilled", "error": "stat", content: []}])
    setIsClean(false)
  }

  const handleStackTypeChange = (e, stackId) => {
    const type = e.target.value
    setStacks(patchList(stacks, stackId, Object.assign({}, stacks[stackId], {"type": type})))
    setIsClean(false)
  }

  const handleMoveDownStack = (e, item) => {
    e.preventDefault()
    setStacks([
      ...stacks.filter((_, i) => i < item),
      stacks[item + 1],
      stacks[item ],
      ...stacks.filter((_, i) => i > item + 1),
    ])
    setIsClean(false)
  }
  
  const handleMoveUpStack = (e, item) => {
    e.preventDefault()
    setStacks([
      ...stacks.filter((_, i) => i < item - 1),
      stacks[item],
      stacks[item - 1],
      ...stacks.filter((_, i) => i > item),
    ])
    setIsClean(false)
  }
  
  
  const handleDeleteRatioItem = (e, itemId) => {
    e.preventDefault()
    setRatio(ratio.filter((_, i) => i != itemId))
    setIsClean(false)
  }
  
  const handleAddRatioItem = (e) => {
    e.preventDefault()
    setRatio([...ratio, {
      "type": "step",
      "error": "stat",
      numerator: [],
      denominator: [],
      style: {}
    }])
    setIsClean(false)
  }

  const handleRatioItemTypeChange = (e, itemId) => {
    const type = e.target.value
    setRatio(patchList(ratio, itemId, Object.assign({}, ratio[itemId], {"type": type})))
    setIsClean(false)
  }

  const handleMoveDownRatioItem = (e, item) => {
    e.preventDefault()
    setRatio([
      ...ratio.filter((_, i) => i < item),
      ratio[item + 1],
      ratio[item ],
      ...ratio.filter((_, i) => i > item + 1),
    ])
    setIsClean(false)
  }
  
  const handleMoveUpRatioItem = (e, item) => {
    e.preventDefault()
    setRatio([
      ...ratio.filter((_, i) => i < item - 1),
      ratio[item],
      ratio[item - 1],
      ...ratio.filter((_, i) => i > item),
    ])
    setIsClean(false)
  }

  const handleRatioItemChangeColor = (e, item) => {
    const color = e.target.value
    setRatio(patchList(ratio, item, Object.assign({}, ratio[item],
      {"style": Object.assign({}, ratio[item].style, {color: color})})))
    setIsClean(false)
  }

  const handleRatioItemNumeratorChange = (e, itemId) => {
    const yields = Array.from(e.target.selectedOptions, option => option.value)
    setRatio(patchList(ratio, itemId, Object.assign({}, ratio[itemId], {"numerator": yields})))
    setIsClean(false)
  }
  
  const handleRatioItemDenominatorChange = (e, itemId) => {
    const yields = Array.from(e.target.selectedOptions, option => option.value)
    setRatio(patchList(ratio, itemId, Object.assign({}, ratio[itemId], {"denominator": yields})))
    setIsClean(false)
  }
  
  const mod_uhepp = Object.assign({}, uhepp, {"stacks": stacks, "ratio": ratio})

  const variations = variationList(mod_uhepp)
  const isVariationReady = noVariationUsed(mod_uhepp) && (variations.length > 0)

  const main_names = mod_uhepp.stacks.map(stack => stack.content.map(item => item.yield).flat()).flat()
  const num_names = mod_uhepp.ratio ? mod_uhepp.ratio.map(item => item.numerator).flat() : []
  const den_names = mod_uhepp.ratio ? mod_uhepp.ratio.map(item => item.denominator).flat() : []
  const all_names = [...main_names, ...num_names, ...den_names]
  const used_var_names = all_names.filter(n => n).map(n => n.split("/")).filter(t => t.length == 3).map(t => t[1])

  const main_namesWithEnv = mod_uhepp.stacks.filter(s => (s.error || []).indexOf("env") != -1).map(stack => stack.content.map(item => item.yield).flat()).flat()
  const num_namesWithEnv = mod_uhepp.ratio ? mod_uhepp.ratio.filter(s =>  (s.error || []).indexOf("env") != -1).map(item => item.numerator).flat() : []
  const den_namesWithEnv = mod_uhepp.ratio ? mod_uhepp.ratio.filter(s => (s.den_error || s.error || []).indexOf("env") != -1).map(item => item.denominator).flat() : []
  const all_namesWithEnv = [...main_namesWithEnv, ...num_namesWithEnv, ...den_namesWithEnv]

  const filter_var = (var_updown, process) => {
    return objfilter(var_updown, (value, key) =>
      ((used_var_names.indexOf(key) != -1) || key == envName)  // active variation
      || all_namesWithEnv.indexOf(process) != -1               // or a process has env
    )
  }
  const pruned = Object.assign({}, mod_uhepp,
    {"yields": objmap(uhepp.yields, (y, name) => Object.assign({}, y, {
      "var_up": filter_var(y.var_up || {}, name), 
      "var_down": filter_var(y.var_down || {}, name), 
    }))}
  )

  const pruned_env = envName == "NOMINAL" ? pruned : Object.assign({}, pruned,
        {stacks: [
          ...pruned.stacks.filter((_, i) => i <= envId),
          ...makeVariationStacks(pruned, envId, envName, "up"),
          ...makeVariationStacks(pruned, envId, envName, "down"),
          ...pruned.stacks.filter((_, i) => i > envId),
        ],
        ratio: [
          ...makeVariationRatio(pruned, envId, envName, "up"),
          ...makeVariationRatio(pruned, envId, envName, "down"),
          ...pruned.ratio,
        ]},
      )

  return <UheppHistUIWithSyst
            width={width}
            size={size}
            height={height}
            uhepp={pruned_env}
            onEnvChange={e => handleEnvelop(e)}
            onEnvStackChange={e => handleEnvStack(e)}
            onStackContentChange={(e, i, j) => handleStackContentChange(e, i, j)}
            onStackItemRename={(e, i, j) => handleStackItemRename(e, i, j)}
            onStackItemChangeColor={(e, i, j) => handleStackItemChangeColor(e, i, j)}
            onAddStackItem={(e, i) => handleAddStackItem(e, i)}
            onDeleteStackItem={(e, i, j) => handleDeleteStackItem(e, i, j)}
            onReset={() => reset()}
            onMoveUpStackItem={(e, i, j) => handleMoveUpStackItem(e, i, j)}
            onMoveDownStackItem={(e, i, j) => handleMoveDownStackItem(e, i, j)}
            onMoveUpStack={(e, i) => handleMoveUpStack(e, i)}
            onMoveDownStack={(e, i) => handleMoveDownStack(e, i)}
            onDeleteStack={(e, i) => handleDeleteStack(e, i)}
            onAddStack={(e) => handleAddStack(e)}
            onStackTypeChange={(e, i) => handleStackTypeChange(e, i)}

            onMoveUpRatioItem={(e, i) => handleMoveUpRatioItem(e, i)}
            onMoveDownRatioItem={(e, i) => handleMoveDownRatioItem(e, i)}
            onDeleteRatioItem={(e, i) => handleDeleteRatioItem(e, i)}
            onAddRatioItem={(e) => handleAddRatioItem(e)}
            onRatioItemTypeChange={(e, i) => handleRatioItemTypeChange(e, i)}
            onRatioItemNumeratorChange={(e, i) => handleRatioItemNumeratorChange(e, i)}
            onRatioItemDenominatorChange={(e, i) => handleRatioItemDenominatorChange(e, i)}
            onRatioItemChangeColor={(e, i) => handleRatioItemChangeColor(e, i)}

            setIsClean={isClean => setIsClean(isClean)}
            isClean={isClean}

            envName={envName}
            envId={envId}
            uuid={uuid}
            origStacks={pruned.stacks}
            origRatio={pruned.ratio}
            origYields={uhepp.yields}
            isVariationReady={isVariationReady}
            variations={variations} />
}

const UheppHistUI = (props) => {
  return <Router><Switch>
    <Route path="/:envName/:envStack" render={() => <UheppHistUIRouted {...props} />} />
    <Route path="/" render={() => <UheppHistUIRouted envName="NOMINAL" envId={0} {...props} />} />
  </Switch></Router>
}

export default UheppHistUI;
